<template>
    <Loading :isLoading="isLoading" />
    <div class="flex ml-5 mt-5 cursor-pointer" @click="redirect_history()" >
        <img src="@/assets/back-arrow.svg">
        Atrás
    </div>
    <div class="bg-white m-0 px-5 md:px-3 md:m-5 ">
        <div class="grid grid-cols-1 md:grid-cols-2 px-0 md:px-0 lg:px-20 mt-10">

            <div class="w-full md:w-4/5 md:mx-auto mt-5">
                <p class="text-3xl font-bold roboto-regular mb-4">{{ questionTitle }}</p>
                <QuotationPersonalDataComponent ref="ComponentsRecruimentDataRef" :product_name="productName.toUpperCase()" :view_class_view="viewClassView" :buttons_styles="buttons_styles" :validations="rulesValidationsaditionalBase" :validationsPersonalData="rulesValidationsPersonalData" :validationsBirthplace="rulesValidationsBirthplace" :validationsQuotationHeightWeight="rulesValidationsQuotationHeightWeight" :diplay_relationship="diplay_relationship"
                :diplay_main_question="false"
                :url_api_birth_place="apiURL"
                :gendersButtons="gendersButtons" :mainQuestionButtons="mainQuestionButtons" :relationshipButtons="relationshipButtons" :civilStatusList="civilStatusList"/>
                
                <div class="w-full flex justify-center">
                   <input type="button" id="button_next" :disabled="!isActive" :class="{'button-disabled cursor-default': !isActive, 'bg-mustardDark cursor-pointer': isActive}" class="w-full text-white px-5 py-2 mx-auto my-4 h-14 md:h-14  rounded text-center font-medium text-xl" @click="next()" value="Continuar">        
                </div>
            </div>
            <div class="w-full bg-white hidden md:block md:py-14">
                <img src="@/assets/Cuenta/vidaSimple/contracting_data.svg" :alt="questionTitle" class="mx-auto" >
            </div>
        </div> 
    </div>
</template>

<script>
import { ref, onMounted, Suspense, watch } from 'vue';
import { helpers, requiredIf, minLength, maxLength } from '@vuelidate/validators';

import Swal from 'sweetalert2';
import Loading from '@/components/Loading/VueLoading.vue';
import { useRouter, useRoute } from 'vue-router';
import { validateMatchRFCwithName } from '@/api/user';
import moment from "moment";
import { availableFormStorage, getLocalStorage, createLocalStorage } from '@/helpers/storage.js';
import { formatCamelize } from "@/helpers/filters.js"
import { normalizeDateFormat } from "@/helpers/dates.js";

export default {
    name: "RecruimentData",
    setup(){
        const apiURL = process.env.VUE_APP_CCAPI;
        const router = useRouter();
        const route = useRoute();
        const isLoading = ref(true);
        const ComponentsRecruimentDataRef = ref(null);
        const validations = ref({});
        const category = ref("");
        const questionTitle = ref('Datos de contratación');
        const imageCategory = ref('personal_info.svg');
        
        const isValidRfc = ref(false);
        const isActive = ref(false);
        const personal_data = ref({});

        const diplay_relationship = ref(false);
        const productName = ref("");
        const isRequiredRfc = ref("");

        const gendersButtons = [
            { id: 'button_female', value: 'mujer', text: 'Mujer' },
            { id: 'button_male', value: 'hombre', text: 'Hombre' }
        ];

        const mainQuestionButtons = [
            { id: 'button_aditional', value: 'Para Adicional', text: 'Para Adicional' },
            { id: 'button_for_socio', value: 'Para socio', text: 'Para socio' }
        ];

        const relationshipButtons = [
            { id: 'button_family', value: 'familiar', text: 'Familiar' },
            { id: 'button_laboral', value: 'laboral', text: 'Laboral' }
        ];

        const civilStatusList = [
            {text:"Casado(a)", value: "casado(a)"},
            {text:"Soltero(a)", value: "soltero(a)"},
            {text:"Divorciado(a)", value: "divorciado(a)"},
            {text:"Unión libre", value: "unión libre"},
            {text:"Viudo(a)", value: "viudo(a)"}
        ];

        let type_request = ref("");

        const user_information_storage = ref(JSON.parse(sessionStorage.getItem("user-information")));
        onMounted(async()=>{
            category.value = getCategory(false);
            productName.value = getCategory(true);
            isActive.value = true;

            type_request.value = "Para socio";
            loadFormStorage();
            isLoading.value = false;
        });

        const redirect_history = () => router.go(-1);

        const loadFormStorage = () => {
            try{
                if(!availableFormStorage('quote-recruitment-data')) return;
                
                const data = getLocalStorage('quote-recruitment-data');

                if(data.is_additional_insured == 'Para Adicional') {
                    diplay_relationship.value = true;
                }else{
                    diplay_relationship.value = false;
                }
                
                const chooseButtonIsAdditionalInsuredSelected = data.is_additional_insured == 'Para socio'? 'for_socio':'aditional';
                const inputIsAdditionalInsuredSelected = document.getElementById(`button_${chooseButtonIsAdditionalInsuredSelected}`);
                inputIsAdditionalInsuredSelected.click();

                const chooseButtonRelationShipSelected = data.relationship == 'familiar'? 'family':'laboral';
                const inputRelationShipInsuredSelected = document.getElementById(`button_${chooseButtonRelationShipSelected}`);
                inputRelationShipInsuredSelected.click();

                ComponentsRecruimentDataRef.value.PersonalDataRef.personalDataModel.rfc = data.rfc;
                ComponentsRecruimentDataRef.value.PersonalDataRef.personalDataModel.curp = data.curp;
                ComponentsRecruimentDataRef.value.PersonalDataRef.personalDataModel.dateBirth = data.birthday;
                ComponentsRecruimentDataRef.value.PersonalDataRef.personalDataModel.paternalName = data.paternal_name;
                ComponentsRecruimentDataRef.value.PersonalDataRef.personalDataModel.maternalName = data.maternal_name;
                ComponentsRecruimentDataRef.value.PersonalDataRef.personalDataModel.firstName = data.first_name;
                ComponentsRecruimentDataRef.value.PersonalDataRef.personalDataModel.secondName = data.second_name;
                ComponentsRecruimentDataRef.value.PersonalDataRef.RefChooseButton.changeButton(data.gender);
                ComponentsRecruimentDataRef.value.BirthPlaceRef.birthPlace.state = data.state;
                ComponentsRecruimentDataRef.value.BirthPlaceRef.municipality_selected = data.municipality;
                ComponentsRecruimentDataRef.value.BirthPlaceRef.city_selected = data.city;
                ComponentsRecruimentDataRef.value.aditionalPersonalInformation.marital_status = data.marital_status;

                ComponentsRecruimentDataRef.value.HeightAndWeightRef.personMeasuresModel.height = data.height;
                ComponentsRecruimentDataRef.value.HeightAndWeightRef.personMeasuresModel.weight = data.weight;
                ComponentsRecruimentDataRef.value.PersonalDataRef.personalDataModel.dateBirth = formatDate(data.date_birth);
            }catch(error){
                console.log("error:", error)
            }
        }

        const formatDate = (date) => {
            var regexFecha = /^\d{2}\/\d{2}\/\d{4}$/;
            
            if (regexFecha.test(date)) {
                return date;
            }
            
            var parts = date.split("-");
            
            var year = parts[0];
            var month = parts[1];
            var day = parts[2];
            
            var dateConvert = day + "/" + month + "/" + year;
            
            return dateConvert;
        }

        watch(type_request, (value, afterValue) => {
            ComponentsRecruimentDataRef.value.aditionalPeronalInformationModelValidation.$reset()
            getDataOfClient(value, afterValue);
        });

        const getCategory = (replace = true) => {
            let product = route.params.product;
            return replace? product.replaceAll("-", " "): product;
        }

        const saveData = (values, category) => {
            const nameStorage = `quote-${category}`;

            let quote = getLocalStorage(nameStorage);
            quote.person.id = 0;
            quote.person.rfc = values.rfc;
            quote.person.curp = values.curp;
            quote.person.name = values.first_name;
            quote.person.middle_name = values.second_name;
            quote.person.paternal_name = values.paternal_name;
            quote.person.maternal_name = values.maternal_name;
            quote.person.gender = values.gender;
            quote.person.birthday = normalizeDateFormat(values.date_birth);
            quote.person.age = 0;
            quote.person.city_of_birth = values.city;
            quote.person.state_name_birth = values.state;
            
            quote.weight = parseInt(values.weight);
            quote.height = parseFloat(values.height.toString()).toFixed(2);
            quote.is_additional_insured = values.is_additional_insured;
            quote.marital_status = values.marital_status;
            quote.relationship = values.relationship;

            if(values.date_birth != "") quote.age = getAgeByDate(normalizeDateFormat(values.date_birth));
            createLocalStorage(nameStorage, quote)
        }
        async function exceptionMessageRFC() {
            try {
                const respuesta = await Swal.fire({
                    title:
                    "Los datos de nombre no coinciden con el RFC",
                    html: `¿Es correcto y desea continuar con el registro?`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#FEB72B",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sí",
                    cancelButtonText: "No",
                    allowOutsideClick: false
                });
                if (respuesta.isConfirmed) {
                    // El usuario hizo clic en "Sí"
                    // Realiza la acción deseada aquí
                    console.log('El usuario seleccionó Sí');
                    isValidRfc.value = true;
                } else if (respuesta.isDenied || respuesta.dismiss === Swal.DismissReason.cancel) {
                    // El usuario hizo clic en "No" o cerró la ventana emergente
                    console.log('El usuario seleccionó No');
                    isValidRfc.value = false;
                }
            } catch(err){
                console.log("error; " + err);
            }

        }

        async function validityRFC( rfc, birthday, name, middleName, paternalName, maternalName ){
            let validRfc = false;
            
            await validateMatchRFCwithName(rfc, birthday, name, middleName, paternalName, maternalName ).then(response => {
                if(response.status == 200){
                    validRfc = true;
                    return validRfc;
                }
                }).catch(async ( error )=> {
                if (error.response.status == 422) {
                    validRfc = false;
                    return validRfc;
                };

            });
            
            return validRfc;
        }

        const validateMatchStrings = (str, matchStr) => {
            str = str.toString().trim().toUpperCase().substr(0, 10);
            matchStr = matchStr.toString().trim().toUpperCase().substr(0, 10);

            return str === matchStr;
        }

        const validateCurpStructure = (curp) => {
            let curp_pattern =
                /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
            const coincidences = curp.match(curp_pattern);
            return coincidences == null ? false : true;
        }

        let viewClassView = {
            classInputs:{
                valid:"border-blue-600",
                no_valid:"border-red-600",
                default:""
            },
            div_container_error:{
                container:"my-2",
                text_error:"text-red-600",
            },
            div_container:"w-full",
        };

        const buttons_styles = {
            buttonSelected:"",
            activeColorBackground: '#feb72b !important',
            inactiveColorBackground: '#FFFFFF !important',
            activeColorText: '#FFFFFF !important',
            inactiveColorText: '#feb72b !important',
            activeBorderColor: '#feb72b',
            inactiveBorderColor: '#feb72b'
        };

        const rulesValidationsaditionalBase = {
            is_additional_insured: {
                required: helpers.withMessage("El campo es requerido.", requiredIf( (value,index) => {
                    // type_request.value = index.is_additional_insured;
                    index.is_additional_insured = type_request;
                    return false;
                })),
                $autoDirty: true 
            },
            marital_status: {
                required: helpers.withMessage("El campo es requerido.", requiredIf(function(value, e){
                    return true;
                })),
                $autoDirty: true 
            },
            relationship: {
                required: helpers.withMessage("El campo es requerido.", requiredIf((function(value, e){
                    if(e.is_additional_insured != 'Para Adicional') return false;
                    return true;
                }))),
                $autoDirty: true 
            }
        };

        let rulesValidationsPersonalData = {
            rfc:{
                required:helpers.withMessage("El campo rfc es requerido.", requiredIf(function(value, e){
                    /* if(ComponentsRecruimentDataRef.value !== null && ComponentsRecruimentDataRef.value.aditionalPersonalInformation.is_additional_insured != 'Para Adicional' && ComponentsRecruimentDataRef.value.aditionalPersonalInformation.is_additional_insured != "") return false; */
                    return true;
                })),
                rfcEqualsMember: helpers.withMessage("RFC debe ser distinto al del socio", function(value, obj){
                    /* if(ComponentsRecruimentDataRef.value.aditionalPersonalInformation.is_additional_insured == 'Para Adicional'){
                        if(value.length == 13){
                            if(user_information_storage.value.user_data.rfc == value){
                                return false
                            }
                            return true;
                        }
                    } */
                    return true;
                }),
                rfcMatchWithNames: helpers.withAsync(async function(value, obj){
                    if(value.length == 13){
                            const response = await  validityRFC(obj.rfc, obj.dateBirth, obj.firstName, obj.secondName, obj.paternalName, obj.maternalName);
                            if(isValidRfc.value == false){
                                if(response == false){
                                    exceptionMessageRFC();
                                }
                            }

                    }
                    return true;
                }),
                minLength: helpers.withMessage("El campo debe contener mínimo 13 caracteres",  minLength(13)),
                maxLength: helpers.withMessage("El campo debe contener máximo 13 caracteres",  maxLength(13)),
                $autoDirty: true 
            },
            curp:{
                required: helpers.withMessage("El campo curp es requerido.", requiredIf(function(){
                    /* if(ComponentsRecruimentDataRef.value !== null && ComponentsRecruimentDataRef.value.aditionalPersonalInformation.is_additional_insured != 'Para Adicional' && ComponentsRecruimentDataRef.value.aditionalPersonalInformation.is_additional_insured != "" ) return false; */
                    return true;
                })),
                rfcEqualsMember: helpers.withMessage("CURP debe ser distinto al del socio", function(value, obj){
                    if(ComponentsRecruimentDataRef.value.aditionalPersonalInformation.is_additional_insured == 'Para Adicional'){
                        if(value.length == 18){
                            if(user_information_storage.value.user_data.curp == value){
                                return false
                            }
                            return true;
                        }
                    } 
                    return true;
                }),
                minLength: helpers.withMessage(`El campo debe contener mínimo 18 caracteres`, minLength(18)),
                maxLength: helpers.withMessage(`El campo debe contener máximo 18 caracteres`, maxLength(18)),
                structureIsValid: helpers.withMessage("El formato del CURP no es correcto. Ejemplo de formato correcto: LOMP8206281HSRTZR02" , function (value) {
                    return value == ""? true : validateCurpStructure(value.toString().toUpperCase());
                }),
                validateMatchBetweenCurpAndRfc: helpers.withMessage(`Los primeros 10 caracteres del campo CURP no coinciden con los primeros 10 caracteres del campo RFC`, function (value, e) {
                    return validateMatchStrings(value, e.rfc)
                }),
                $autoDirty: true 
            },
            dateBirth:{
                required:helpers.withMessage("El campo fecha de nacimiento es requerido.", async ()=> {
                    /* if(ComponentsRecruimentDataRef.value !== null && ComponentsRecruimentDataRef.value.aditionalPersonalInformation.is_additional_insured != 'Para Adicional' && ComponentsRecruimentDataRef.value.aditionalPersonalInformation.is_additional_insured != "") return false; */
                    return true;
                }),
                $autoDirty: true 
            },
            paternalName:{
                required:helpers.withMessage("El campo apellido paterno es requerido.",   requiredIf((value, e)=> {
                    return ComponentsRecruimentDataRef.value.PersonalDataRef.personalDataModel.maternalName.trim() == "" && ComponentsRecruimentDataRef.value.PersonalDataRef.personalDataModel.paternalName.trim() == "" ? true : false
                })),
                minLength: helpers.withMessage(`El campo debe contener mínimo 2 caracteres`,  minLength(2)),
                maxLength: helpers.withMessage(`El campo debe contener máximo 30 caracteres`,  maxLength(30)),
                $autoDirty: true 
            },
            maternalName:{
                required:helpers.withMessage("El campo apellido materno es requerido.",  requiredIf((value, e)=> {
                    return ComponentsRecruimentDataRef.value.PersonalDataRef.personalDataModel.paternalName.trim() == "" && ComponentsRecruimentDataRef.value.PersonalDataRef.personalDataModel.maternalName.trim() == "" ? true : false
                })),
                minLength: helpers.withMessage(`El campo debe contener mínimo 2 caracteres`,  minLength(2)),
                maxLength: helpers.withMessage(`El campo debe contener máximo 30 caracteres`,  maxLength(30)),
                $autoDirty: true 
            },
            firstName:{
                required:helpers.withMessage("El campo primer nombre es requerido.",  requiredIf(()=> {
                    /* if(ComponentsRecruimentDataRef.value !== null && ComponentsRecruimentDataRef.value.aditionalPersonalInformation.is_additional_insured != 'Para Adicional' && ComponentsRecruimentDataRef.value.aditionalPersonalInformation.is_additional_insured != "") return false; */
                    return true;
                })),
                minLength: helpers.withMessage(`El campo debe contener mínimo 2 caracteres`,  minLength(2)),
                maxLength: helpers.withMessage(`El campo debe contener máximo 30 caracteres`,  maxLength(30)),
                $autoDirty: true 
            },
            secondName:{
                required:helpers.withMessage("El campo segundo nombre es requerido.", requiredIf( () => {
                    return false;
                })),
                $autoDirty: true 
            },
            gender:{
                required:helpers.withMessage("El campo genero es requerido.", requiredIf(function(){
                    /* if(ComponentsRecruimentDataRef.value !== null && ComponentsRecruimentDataRef.value.aditionalPersonalInformation.is_additional_insured != 'Para Adicional' && ComponentsRecruimentDataRef.value.aditionalPersonalInformation.is_additional_insured != "") return false; */
                    return true;
                })),
                $autoDirty: true 
            }
        };

        let rulesValidationsBirthplace = {
            country:{
                require : helpers.withMessage("El campo es requerido", requiredIf(function(){
                    /* if(ComponentsRecruimentDataRef.value !== null && ComponentsRecruimentDataRef.value.aditionalPersonalInformation.is_additional_insured != 'Para Adicional'&& ComponentsRecruimentDataRef.value.aditionalPersonalInformation.is_additional_insured != "") return false; */
                    return true;
                })),
                $autoDirty: true 
            },
            state:{
                require : helpers.withMessage("El campo es requerido", requiredIf(function(){
                    /* if(ComponentsRecruimentDataRef.value !== null && ComponentsRecruimentDataRef.value.aditionalPersonalInformation.is_additional_insured != 'Para Adicional' && ComponentsRecruimentDataRef.value.aditionalPersonalInformation.is_additional_insured != "") return false; */
                    return true;
                })),
                $autoDirty: true 
            },
            municipality:{
                require : helpers.withMessage("El campo es requerido", requiredIf(function(){
                    /* if(ComponentsRecruimentDataRef.value !== null && ComponentsRecruimentDataRef.value.aditionalPersonalInformation.is_additional_insured != 'Para Adicional' && ComponentsRecruimentDataRef.value.aditionalPersonalInformation.is_additional_insured != "") return false; */
                    return true;
                })),
                $autoDirty: true 
            },
            city:{
                require : helpers.withMessage("El campo es requerido", requiredIf(function(){
                    /* if(ComponentsRecruimentDataRef.value !== null && ComponentsRecruimentDataRef.value.aditionalPersonalInformation.is_additional_insured != 'Para Adicional' && ComponentsRecruimentDataRef.value.aditionalPersonalInformation.is_additional_insured != "") return false; */
                    return ComponentsRecruimentDataRef.value.BirthPlaceRef.birthPlace.city.length > 0;
                })),
                $autoDirty: true 
            }
        };

        let rulesValidationsQuotationHeightWeight = {
            height: {
                required: helpers.withMessage('El campo es requerido', requiredIf(function(){
                    return true;
                })),
                minLenght: helpers.withMessage('La altura mínima es 1.20 mts', function(value, index){
                    let height = Number(index.height);
                    if(height >= 1.2){
                        return true;
                    }
                    return false;
                }),
                numberFormat: helpers.withMessage('Únicamente se aceptan valores numéricos.', function (data, index) {

                    return new RegExp(/^[0-9]+([.][0-9]{1,2})?$/).test(index.height.toString());
                }),
                valueTwoNumber:helpers.withMessage("Después del punto solo se admiten 2 decimales.",function(data,index) {

                    if(index.height!=""){
                        return new RegExp(/^[0-9]+([.][0-9]{1,2})?$/).test(index.height.toString())
                    }
                    else return true;
                }),
                maxHeigth:helpers.withMessage(`La altura maxima es 2.52 mts.`, function(data,index){

                    if(index.height > 0 && index.height > 2.52){
                        return false
                    }
                    if(index.height == 0){
                        return false
                    } else {
                        return true
                    }
                }),
                $autoDirty: true 
            },
            weight: {
                required: helpers.withMessage('El campo es requerido', requiredIf(function(){
                    return true;
                })),
                valueTwoNumber:helpers.withMessage("Después del punto solo se admiten 2 decimales.",function(data,index) {

                    if(index.weight!=""){
                        return new RegExp(/^[0-9]+([.][0-9]{1,2})?$/).test(index.weight.toString())
                    }
                    else return true;
                }),
                maximum_weight: helpers.withMessage(`El peso maximo son 200 Kg.`, function(data,index){

                    if(index.weight!=""){
                        return parseFloat(index.weight) <= 200
                    }
                    else{
                        return true
                    }
                    
                }),
                minimun_weight: helpers.withMessage(`El peso mínimo son 40 Kg.`, function(data,index){

                    if(index.weight!=0){
                        return parseFloat(index.weight) >= 40
                    }
                    if(index.weight==0){
                        return false
                    }else{
                        return true
                    }                
                }),
                $autoDirty: true 
            }
        };

        const next = async ()   => {
            const objPersonalData = ComponentsRecruimentDataRef.value.PersonalDataRef.personalDataModel;

            if(blank(objPersonalData.paternalName) && blank(objPersonalData.maternalName)){
                await Swal.fire({
                    title:
                    "Aviso",
                    html: `Favor de capturar por lo menos un apellido`,
                    icon: "warning",
                    confirmButtonColor: "#FEB72B",
                });
                return;
            }

            const response = await validityRFC(objPersonalData.rfc, objPersonalData.dateBirth, objPersonalData.firstName, objPersonalData.secondName, objPersonalData.paternalName, objPersonalData.maternalName);
            console.log("response: ", response);
            console.log("isValidRFC: ", isValidRfc.value);

            if(isValidRfc.value == false){
                if(response == false){
                    exceptionMessageRFC();
                    return;
                }

            }
            const ComponentsRecruimentDataRefs = ComponentsRecruimentDataRef.value.execute_validation();
            if(!ComponentsRecruimentDataRefs) return false ;

            ComponentsRecruimentDataRefs.height = parseFloat(ComponentsRecruimentDataRefs.height.toString()).toFixed(2)
            createLocalStorage('quote-recruitment-data', ComponentsRecruimentDataRefs);
            if(ComponentsRecruimentDataRefs.is_additional_insured != 'Para Socio'){
                saveData(ComponentsRecruimentDataRefs, category.value)
                router.push('contacto')
                return;
            }
            saveData(ComponentsRecruimentDataRefs, category.value)
            router.push('datos-personales')
        }
        
        const getDataOfClient = (status, after) => {
            if(after != "") cleanInputsForComponent();

            diplay_relationship.value = true;
            personal_data.value = {};
            if(status == "Para socio") {
                diplay_relationship.value = false;
                
                const isAvailable = availableFormStorage('quote-recruitment-data'); 
                console.log("isAvailable: ", isAvailable);
                if(isAvailable && (after != 'Para Adicional' && status!= "Para socio")) return;
               
                const user_information = JSON.parse(sessionStorage.getItem("user-information"));
                setDataInComponent(user_information);
            }
        }

        const setDataInComponent = (person) => {
            const isAvailable = availableFormStorage('quote-recruitment-data'); 
            if(isAvailable){
                const quoteRecruitmentData = JSON.parse(localStorage.getItem("quote-recruitment-data"));
                person = {
                    user_data: {
                        ...quoteRecruitmentData,
                        birthday: quoteRecruitmentData.date_birth,
                        middle_name: quoteRecruitmentData.second_name,
                        name: quoteRecruitmentData.first_name,
                    }
                }
            }
            console.log(formatCamelize(person.user_data.marital_status.toLowerCase()))
            ComponentsRecruimentDataRef.value.PersonalDataRef.personalDataModel.rfc = person.user_data.rfc;
            ComponentsRecruimentDataRef.value.PersonalDataRef.personalDataModel.curp = person.user_data.curp;
            ComponentsRecruimentDataRef.value.PersonalDataRef.personalDataModel.dateBirth = person.user_data.birthday;
            ComponentsRecruimentDataRef.value.PersonalDataRef.personalDataModel.paternalName = person.user_data.paternal_name;
            ComponentsRecruimentDataRef.value.PersonalDataRef.personalDataModel.maternalName = person.user_data.maternal_name;
            ComponentsRecruimentDataRef.value.PersonalDataRef.personalDataModel.firstName = person.user_data.name;
            ComponentsRecruimentDataRef.value.PersonalDataRef.personalDataModel.secondName = person.user_data.middle_name;
            ComponentsRecruimentDataRef.value.PersonalDataRef.RefChooseButton.changeButton(person.user_data.gender);
            ComponentsRecruimentDataRef.value.BirthPlaceRef.birthPlace.state = person.user_data.state;
            ComponentsRecruimentDataRef.value.BirthPlaceRef.municipality_selected = person.user_data.municipality;
            ComponentsRecruimentDataRef.value.BirthPlaceRef.city_selected = person.user_data.city;
            ComponentsRecruimentDataRef.value.aditionalPersonalInformation.marital_status = person.user_data.marital_status.toLowerCase();
        }

        const getAgeByDate = (date) => {
            let birthday = moment(date);
            let today = moment();
            let age = today.diff(birthday,"years");
            return age;
        }

        const cleanInputsForComponent = () => {
            ComponentsRecruimentDataRef.value.aditionalPersonalInformation.is_additional_insured = type_request;
            ComponentsRecruimentDataRef.value.PersonalDataRef.personalDataModel.rfc = "";
            ComponentsRecruimentDataRef.value.PersonalDataRef.personalDataModel.curp = "";
            ComponentsRecruimentDataRef.value.PersonalDataRef.personalDataModel.dateBirth = "";
            ComponentsRecruimentDataRef.value.PersonalDataRef.personalDataModel.paternalName = "";
            ComponentsRecruimentDataRef.value.PersonalDataRef.personalDataModel.maternalName = "";
            ComponentsRecruimentDataRef.value.PersonalDataRef.personalDataModel.firstName = "";
            ComponentsRecruimentDataRef.value.PersonalDataRef.personalDataModel.secondName = "";
            ComponentsRecruimentDataRef.value.PersonalDataRef.personalDataModel.gender = "";
            ComponentsRecruimentDataRef.value.PersonalDataRef.RefChooseButton.changeButton("");
            ComponentsRecruimentDataRef.value.BirthPlaceRef.birthPlace.state = "";
            ComponentsRecruimentDataRef.value.BirthPlaceRef.municipality_selected = "";
            ComponentsRecruimentDataRef.value.BirthPlaceRef.city_selected = "";
            ComponentsRecruimentDataRef.value.aditionalPersonalInformation.marital_status = "";
            ComponentsRecruimentDataRef.value.HeightAndWeightRef.personMeasuresModel.height = "";
            ComponentsRecruimentDataRef.value.HeightAndWeightRef.personMeasuresModel.weight = "";
        }

        const blank = (value) => {
            return [value].filter(x=>x).length == 0
        }
        return {
            next,
            isActive,
            isLoading,
            validations,
            questionTitle,
            imageCategory,
            rulesValidationsPersonalData,
            rulesValidationsBirthplace,
            rulesValidationsQuotationHeightWeight,
            buttons_styles,
            rulesValidationsaditionalBase,
            viewClassView,
            ComponentsRecruimentDataRef,
            diplay_relationship,
            mainQuestionButtons,
            gendersButtons,
            relationshipButtons,
            civilStatusList,
            productName,
            apiURL,
            redirect_history
        }
    },
    components:{
        Loading,
        Suspense
    }

}
</script>

<style>
.question-container > div > div {
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 15px;
}

.question-container > div > div > button{
    border-radius:5px;
    width:49%;
}

.question-container>div>label, .question-container>div>input, .question-container>div>button {
     font-family: "roboto-regular";
}

.question-container > div > div > button.active {
    background-color: #810042;
    color: white;
    border-color: #810042;
}

.question-container > div > div > button {
    box-sizing: border-box;
    background: #D9D9D9;
    color: white;
    border-radius: 5px;
    font-size: 18px;
    margin-inline: 1px;
}

.question-container>div>label {
    font-size: 18px;
    line-height: 30px;
    color: #485462;
}

.card-shadow {
  box-shadow: 0px 4px 6px -3px rgb(178 176 176);
  -webkit-box-shadow: 0px 4px 6px -3px rgb(178 176 176);
  -moz-box-shadow: 0px 4px 6px -3px rgb(178 176 176);
}

.content_textarea_0 {
    margin-top: 20px;
}

.content_date_3, .content_date_4 {
    margin-top: 9px;
}

.vpd-input-group input {
    flex-grow: 1;
    border: 2px solid #e5e7eb;
    border-right: 2px solid #e5e7eb;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    line-height: 30px;
    padding: 0 10px;
    height: 43px;
}

.content_textarea_6 {
    display:flex;
}

.content_checkbox_1 > div {
    width:100%!important;
}


@media only screen and (min-width: 600px) {
    .content_date_3, .content_date_4 {
        display: block;
    }
}

@media only screen and (max-width: 600px) {
    .content_date_3, .content_date_4 {
        display: flex;
    }
}

#input_rfc_contracting_data_1,
#input_curp_contracting_data_2{
    text-transform: uppercase;
}
</style>
<style scoped>
.button-disabled {
    background-color: #EDD191;
}



@media only screen and (min-width: 600px) {
    .detail-container {
        background-color: #FAFAFA;
    }
    .content_date_3, .content_date_4 {
        display: block;
    }
}
@media only screen and (max-width: 600px) {
    .bg-container {
        background-color: #FCFCFC;
    } 
    .content_date_3, .content_date_4 {
        display: flex;
    }
}
button {
    font-size: 19px!important;
}

</style>